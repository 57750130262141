import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Typed from "react-typed";
import useIsMobile from "../../utils/isMobile";
import axios from "axios";
import toast from "react-hot-toast";

const Jobs = [
  "CEO",
  "CMO",
  "VP",
  "Director",
  "Head of / Manager",
  "Analyst",
  "Associate",
  "Coordinator",
  "Trainee / Intern",
  "Student / Researcher",
  "Other",
];
const Countries = [
  "United States",
  "United Kingdom",
  "Australia",
  "Canada",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cura",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Republic of",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territories",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barth",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad And Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
const Companies = [
  "Agency",
  "Retailer / Brand",
  "Web Publisher",
  "App Developer",
];
const ContactContents = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [jobOpen, setJobOpen] = useState(false);
  const [jobValue, setJobValue] = useState(false);
  const [countryOpen, setCountryOpen] = useState(false);
  const [countryValue, setCountryValue] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [companyValue, setCompanyValue] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [jobValueError, setJobValueError] = useState("");
  const [countryValueError, setCountryValueError] = useState("");
  const [companyValueError, setCompanyValueError] = useState("");
  const [answer, setAnswer] = useState("");
  const typedRef = useRef(null);

  const [typedString, setTypedString] = useState([
    "AIX leads your business to success through the world's largest open commerce dataset, industry-leading AI, an integrated platform for both supply and demand sides, and an all-in-one solution. ^1000" +
      "<Br /><Br />From large enterprises and DTC brands to retailers and agencies, AIX's platform supports everyone to achieve success through commerce media. AIX caters to customers of various sizes, offering customized solutions that align with their business objectives. ^1000" +
      "<Br /><Br /><strong>Industry-Leading AI</strong><br /> AIX optimizes your business with industry-leading AI. This AI analyzes your data and executes the most suitable ads for your business. ^1000" +
      "<Br /><Br /><strong>All-in-One Solution</strong><br /> AIX provides an all-in-one solution to lead your business to success. This solution assists in formulating, executing, and analyzing your ad strategies. ^1000" +
      "<Br /><Br /><Strong>AIX offers the optimal solutions to lead your business to success. <br /> Contact AIX today to discover how it can guide your business to success.</Strong> ^1000",
  ]);

  const openJobList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setJobOpen(true);
    setCountryOpen(false);
    setCompanyOpen(false);
  };
  const closeJobList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setJobOpen(false);
  };

  const selectJob = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (jobOpen) {
      setJobValue(
        e.target.querySelector("span")?.innerText || e.target.innerText
      );
      setJobOpen(false);
    }
  };

  const openCountryList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCountryOpen(true);
    setJobOpen(false);
    setCompanyOpen(false);
  };
  const closeCountryList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCountryOpen(false);
  };

  const selectCountry = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (countryOpen) {
      setCountryValue(
        e.target.querySelector("span")?.innerText || e.target.innerText
      );
      setCountryOpen(false);
    }
  };

  const openCompanyList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCompanyOpen(true);
    setJobOpen(false);
    setCountryOpen(false);
  };
  const closeCompanyList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCompanyOpen(false);
  };

  const selectCompany = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (companyOpen) {
      setCompanyValue(
        e.target.querySelector("span")?.innerText || e.target.innerText
      );
      setCompanyOpen(false);
    }
  };

  const onSubmit = async () => {
    let error = false;

    if (!firstName) {
      error = true;
      setFirstNameError("Please fill out First name!");
    } else {
      setFirstNameError("");
    }

    if (!lastName) {
      error = true;
      setLastNameError("Please fill out Last name!");
    } else {
      setLastNameError("");
    }

    if (!email) {
      error = true;
      setEmailError("Please fill out Email!");
    } else {
      setEmailError("");
    }

    if (!phone) {
      error = true;
      setPhoneError("Please fill out Phone Number!");
    } else {
      setPhoneError("");
    }

    if (!company) {
      error = true;
      setCompanyError("Please fill out Company!");
    } else {
      setCompanyError("");
    }

    if (!jobValue) {
      error = true;
      setJobValueError("Please select the job role!");
    } else {
      setJobValueError("");
    }

    if (!countryValue) {
      error = true;
      setCountryValueError("Please select the country!");
    } else {
      setCountryValueError("");
    }

    if (!companyValue) {
      error = true;
      setCompanyValueError("Please select the company type!");
    } else {
      setCompanyValueError("");
    }

    const body = {
      firstName: firstName,
      LastName: lastName,
      BuisnessEmail: email,
      Phone: phone,
      Company: company,
      JobRole: jobValue,
      Country: countryValue,
      CompanyType: companyValue,
      Answer: answer,
    };

    try {
      const response = await axios.post(
        "https://ads.aixlab.co/svc/contact.php?m=add_aixlab",
        body
      );

      if (response.status === 200) {
        toast.success("Submitted!!");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setCompany("");
        setJobValue("");
        setCountryValue("");
        setCompanyValue("");
        setAnswer("");
      } else {
        toast.error("Something went wrong...");
      }
    } catch (error) {
      toast.error("Something went wrong...");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    switch (i18n.language) {
      case "en": {
        setTypedString([
          "AIX leads your business to success through the world's largest open commerce dataset, industry-leading AI, an integrated platform for both supply and demand sides, and an all-in-one solution. ^1000" +
            "<Br /><Br />From large enterprises and DTC brands to retailers and agencies, AIX's platform supports everyone to achieve success through commerce media. AIX caters to customers of various sizes, offering customized solutions that align with their business objectives. ^1000" +
            "<Br /><Br /><strong>Industry-Leading AI</strong><br /> AIX optimizes your business with industry-leading AI. This AI analyzes your data and executes the most suitable ads for your business. ^1000" +
            "<Br /><Br /><strong>All-in-One Solution</strong><br /> AIX provides an all-in-one solution to lead your business to success. This solution assists in formulating, executing, and analyzing your ad strategies. ^1000" +
            "<Br /><Br /><Strong>AIX offers the optimal solutions to lead your business to success. <br /> Contact AIX today to discover how it can guide your business to success.</Strong> ^1000",
        ]);
        break;
      }
      case "ko": {
        setTypedString([
          "AIX는 거래 규모의 오픈 상업 데이터 세트, 산업 최고의 AI, 공급 및 수요 측면을 모두 통합한 플랫폼, 그리고 올인원 솔루션을 통해 비즈니스를 성공으로 이끕니다. ^1000" +
            "<Br /><Br />대기업부터 DTC 브랜드, 소매업체 및 에이전시까지, AIX의 플랫폼은 상업 미디어를 통한 성공을 위해 모두를 지원합니다. AIX는 고객의 비즈니스 목표와 일치하는 맞춤형 솔루션을 제공하여 다양한 크기의 고객을 대상으로합니다. ^1000" +
            "<Br /><Br /><strong>산업 최고의 AI</strong><br /> AIX는 산업 최고의 AI로 비즈니스를 최적화합니다. 이 AI는 데이터를 분석하고 비즈니스에 가장 적합한 광고를 실행합니다. ^1000" +
            "<Br /><Br /><strong>올인원 솔루션</strong><br /> AIX는 비즈니스를 성공으로 이끄는 올인원 솔루션을 제공합니다. 이 솔루션은 광고 전략을 수립, 실행 및 분석하는 데 도움을 줍니다. ^1000" +
            "<Br /><Br /><Strong>AIX는 비즈니스를 성공으로 이끄는 최적의 솔루션을 제공합니다. <br /> 비즈니스를 성공으로 이끄는 방법을 발견하려면 오늘 AIX에 문의하십시오.</Strong> ^1000",
        ]);
        break;
      }
      case "cn": {
        setTypedString([
          "AIX通过世界上最大的开放商业数据集、领先的人工智能、集成供需双方的平台以及一体化解决方案，将您的业务引领到成功。 ^1000" +
            "<Br /><Br />从大型企业和DTC品牌到零售商和代理商，AIX的平台支持所有人通过商务媒体实现成功。AIX为各种规模的客户提供定制的解决方案，与其业务目标保持一致。 ^1000" +
            "<Br /><Br /><strong>领先行业的人工智能</strong><br /> AIX通过领先行业的人工智能优化您的业务。该人工智能分析您的数据，并执行最适合您业务的广告。 ^1000" +
            "<Br /><Br /><strong>一体化解决方案</strong><br /> AIX提供一体化解决方案，引领您的业务取得成功。这一解决方案有助于制定、执行和分析您的广告策略。 ^1000" +
            "<Br /><Br /><Strong>AIX提供引领您的业务取得成功的最佳解决方案。 <br /> 立即联系AIX，了解它如何引导您的业务取得成功。</Strong> ^1000",
        ]);
        break;
      }
      case "jp": {
        setTypedString([
          "AIXは、世界最大のオープンコマースデータセット、業界トップのAI、供給側と需要側の両方の統合プラットフォーム、オールインワンソリューションを活用してビジネスを成功に導きます。 ^1000" +
            "<Br /><Br />大手企業、DTCブランド、小売業者、エージェンシーから小規模事業者まで、AIXのプラットフォームは、ビジネス目標に合わせたカスタマイズされたソリューションを提供し、コマースメディアを通じて成功を収めるのをサポートします。 AIXはさまざまなサイズの顧客に対応し、ビジネス戦略に合致したカスタマイズされたソリューションを提供します。 ^1000" +
            "<Br /><Br /><strong>業界トップのAI</strong><br /> AIXは業界トップのAIを活用してビジネスを最適化します。このAIはデータを分析し、ビジネスに最適な広告を実行します。 ^1000" +
            "<Br /><Br /><strong>オールインワンソリューション</strong><br /> AIXはビジネスを成功に導くためのオールインワンソリューションを提供します。このソリューションは、広告戦略の策定、実行、分析をサポートします。 ^1000" +
            "<Br /><Br /><Strong>AIXはビジネスを成功に導くための最適なソリューションを提供します。 <br /> AIXにお問い合わせいただき、ビジネスを成功に導く方法を発見してください。</Strong> ^1000",
        ]);
        break;
      }
      case "pt": {
        setTypedString([
          "A AIX conduz o seu negócio ao sucesso por meio do maior conjunto de dados de comércio aberto do mundo, IA líder do setor, uma plataforma integrada para ambos os lados da oferta e demanda, e uma solução completa. ^1000" +
            "<Br /><Br />De grandes empresas e marcas DTC a varejistas e agências, a plataforma da AIX oferece suporte a todos para alcançar o sucesso por meio da mídia de comércio. A AIX atende a clientes de diversos tamanhos, oferecendo soluções personalizadas que se alinham com seus objetivos comerciais. ^1000" +
            "<Br /><Br /><strong>IA Líder do Setor</strong><br /> A AIX otimiza o seu negócio com IA líder do setor. Esta IA analisa seus dados e executa os anúncios mais adequados para o seu negócio. ^1000" +
            "<Br /><Br /><strong>Solução Completa</strong><br /> A AIX oferece uma solução completa para liderar o seu negócio ao sucesso. Esta solução ajuda na formulação, execução e análise de suas estratégias de anúncios. ^1000" +
            "<Br /><Br /><Strong>A AIX oferece as soluções ideais para conduzir o seu negócio ao sucesso. <br /> Entre em contato com a AIX hoje mesmo para descobrir como ela pode guiar o seu negócio ao sucesso.</Strong> ^1000",
        ]);
        break;
      }
      case "es": {
        setTypedString([
          "AIX lidera su negocio hacia el éxito a través del conjunto de datos de comercio abierto más grande del mundo, la IA líder en la industria, una plataforma integrada para ambos lados de la oferta y la demanda, y una solución todo en uno. ^1000" +
            "<Br /><Br />Desde grandes empresas y marcas DTC hasta minoristas y agencias, la plataforma de AIX brinda soporte a todos para lograr el éxito a través de los medios de comercio. AIX atiende a clientes de diferentes tamaños, ofreciendo soluciones personalizadas que se alinean con sus objetivos comerciales. ^1000" +
            "<Br /><Br /><strong>IA Líder en la Industria</strong><br /> AIX optimiza su negocio con IA líder en la industria. Esta IA analiza sus datos y ejecuta los anuncios más adecuados para su negocio. ^1000" +
            "<Br /><Br /><strong>Solución Todo en Uno</strong><br /> AIX proporciona una solución todo en uno para liderar su negocio hacia el éxito. Esta solución ayuda en la formulación, ejecución y análisis de sus estrategias publicitarias. ^1000" +
            "<Br /><Br /><Strong>AIX ofrece las soluciones óptimas para liderar su negocio hacia el éxito. <br /> Póngase en contacto con AIX hoy mismo para descubrir cómo puede guiar su negocio hacia el éxito.</Strong> ^1000",
        ]);
        break;
      }

      default: {
        setTypedString([
          "AIX leads your business to success through the world's largest open commerce dataset, industry-leading AI, an integrated platform for both supply and demand sides, and an all-in-one solution. ^1000" +
            "<Br /><Br />From large enterprises and DTC brands to retailers and agencies, AIX's platform supports everyone to achieve success through commerce media. AIX caters to customers of various sizes, offering customized solutions that align with their business objectives. ^1000" +
            "<Br /><Br /><strong>Industry-Leading AI</strong><br /> AIX optimizes your business with industry-leading AI. This AI analyzes your data and executes the most suitable ads for your business. ^1000" +
            "<Br /><Br /><strong>All-in-One Solution</strong><br /> AIX provides an all-in-one solution to lead your business to success. This solution assists in formulating, executing, and analyzing your ad strategies. ^1000" +
            "<Br /><Br /><Strong>AIX offers the optimal solutions to lead your business to success. <br /> Contact AIX today to discover how it can guide your business to success.</Strong> ^1000",
        ]);
        break;
      }
    }
    if (typedRef.current) {
      typedRef.current.reset();
    }
  }, [i18n.language]);

  return (
    <section class="contents">
      <div class="contact-us2">
        <div class="txt">
          <h1>{t("contact.txt.header")}</h1>
          <div class="txt-box">
            <span id="txt-box">
              <Typed
                strings={typedString}
                showCursor
                autoInsertCss
                typeSpeed={20}
                typedRef={(typed) => {
                  typedRef.current = typed;
                }}
              />
            </span>
          </div>
        </div>
        <div class="form">
          <div class="item">
            <h3>
              {t("contact.form.firstname")} <em>*</em>
            </h3>
            <input
              type="text"
              class={`input-txt ${firstNameError ? "error" : ""}`}
              placeholder="John"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <span class="msg ">{firstNameError}</span>
          </div>

          <div class="item">
            <h3>
              {t("contact.form.lastname")} <em>*</em>
            </h3>
            <input
              type="text"
              class={`input-txt ${lastNameError ? "error" : ""}`}
              placeholder="Smith"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <span class="msg ">{lastNameError}</span>
          </div>

          <div class="item">
            <h3>
              {t("contact.form.email")} <em>*</em>
            </h3>
            <input
              type="text"
              class={`input-txt ${emailError ? "error" : ""}`}
              placeholder="j.mith@mybusiness.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <span class="msg ">{emailError}</span>
          </div>

          <div class="item">
            <h3>
              {t("contact.form.phone")} <em>*</em>
            </h3>
            <input
              type="text"
              class={`input-txt ${phoneError ? "error" : ""}`}
              placeholder="012 345 6789"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <span class="msg ">{phoneError}</span>
          </div>

          <div class="item">
            <h3>
              {t("contact.form.company")} <em>*</em>
            </h3>
            <input
              type="text"
              class={`input-txt ${companyError ? "error" : ""}`}
              placeholder="My business"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <span class="msg ">{companyError}</span>
          </div>

          <div class="item" onClick={openJobList}>
            <h3>
              {t("contact.form.jobrole")} <em>*</em>
            </h3>
            <div class={`${"box-select selectbox"} ${jobOpen ? "open" : ""}`}>
              <div class="input-selected">
                <input
                  type="text"
                  class={`input-txt ${jobValueError ? "error" : ""}`}
                  placeholder="Select an answer"
                  value={jobValue || ""}
                  readonly
                />
                <span class="msg ">{jobValueError}</span>
              </div>
              <div class="select-layer">
                <div
                  class="list job"
                  onMouseLeave={jobOpen && !isMobile ? closeJobList : () => {}}
                >
                  <button class="btn-select-close">
                    <span>close</span>
                  </button>
                  <ul onClick={selectJob}>
                    {Jobs.map((job) => (
                      <li key={job}>
                        <label>
                          <input type="radio" name="radio-select" />
                          <span
                            style={
                              jobValue === job
                                ? {
                                    color: "var(--color-font-green)",
                                    fontWeight: "bold",
                                  }
                                : {}
                            }
                          >
                            {job}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="item" onClick={openCountryList}>
            <h3>
              {t("contact.form.country")} <em>*</em>
            </h3>
            <div
              class={`${"box-select selectbox"} ${countryOpen ? "open" : ""}`}
            >
              <div class="input-selected">
                <input
                  type="text"
                  class={`input-txt ${countryValueError ? "error" : ""}`}
                  placeholder="Select an answer"
                  value={countryValue || ""}
                  readonly
                />
                <span class="msg ">{countryValueError}</span>
              </div>
              <div class="select-layer">
                <div
                  class="list country"
                  onMouseLeave={
                    countryOpen && !isMobile ? closeCountryList : () => {}
                  }
                >
                  <button class="btn-select-close">
                    <span>close</span>
                  </button>
                  <ul onClick={selectCountry}>
                    {Countries.map((country) => (
                      <li key={country}>
                        <label>
                          <input type="radio" name="radio-select" />
                          <span
                            style={
                              countryValue === country
                                ? {
                                    color: "var(--color-font-green)",
                                    fontWeight: "bold",
                                  }
                                : {}
                            }
                          >
                            {country}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="item" onClick={openCompanyList}>
            <h3>
              {t("contact.form.companytype")} <em>*</em>
            </h3>
            <div
              class={`${"box-select selectbox"} ${companyOpen ? "open" : ""}`}
            >
              <div class="input-selected">
                <input
                  type="text"
                  class={`input-txt ${companyValueError ? "error" : ""}`}
                  placeholder="Select an answer"
                  value={companyValue || ""}
                  readonly
                />
                <span class="msg ">{companyValueError}</span>
              </div>
              <div class="select-layer">
                <div
                  class="list company"
                  onMouseLeave={
                    companyOpen && !isMobile ? closeCompanyList : () => {}
                  }
                >
                  <button class="btn-select-close">
                    <span>close</span>
                  </button>
                  <ul onClick={selectCompany}>
                    {Companies.map((company) => (
                      <li key={company}>
                        <label>
                          <input type="radio" name="radio-select" />
                          <span
                            style={
                              companyValue === company
                                ? {
                                    color: "var(--color-font-green)",
                                    fontWeight: "bold",
                                  }
                                : {}
                            }
                          >
                            {company}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <script>
                            $("input[name='radio-select']:radio").change(function () {
                                var val = $(this).parent().text();
                                var index =  $(".selectbox .list").index($(this).parent().parent().parent().parent())
                                console.log(index)
                                $('.selectbox .input-txt:eq('+index+')').val(val);
                                $('.box-select').removeClass('open');
                                $('.select-layer').eq(index).fadeOut(200);
                            });
                            $(".selectbox .input-txt").on('click', function(){
                                var index =  $(".selectbox .input-txt").index($(this))
                                if ($('.box-select').eq(index).hasClass('open')) {
                                    $('.box-select').eq(index).removeClass('open');
                                    $('.select-layer').eq(index).fadeOut(200);
                                } else {
                                    $('.box-select').eq(index).addClass('open');
                                    $('.select-layer').eq(index).fadeIn(200);
                                }
                            });
                            $(".select-layer").on('mouseleave', function(){
                                $('.box-select').removeClass('open');
                                $('.select-layer').fadeOut(200);
                            });

                            $(".btn-select-close").on('click', function(){
                                $('.box-select').removeClass('open');
                                $('.select-layer').fadeOut(200);
                            });

                        </script> */}
            </div>
          </div>

          <div class="item">
            <h3>
              {t("contact.item.header1")} <br />
              {t("contact.item.header2")}
            </h3>
            <textarea
              type="text"
              class="input-txtarea"
              placeholder="Enter your answer here"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            ></textarea>
          </div>

          <div class="btn-bottom">
            <button class="btn btn-primary" type="submit" onClick={onSubmit}>
              <strong>{t("contact.submit")}</strong>
            </button>

            {/* <script>
                        //에러메시지 테스트용
                        $(".btn-primary").on('click', function(){
                            $('.item:eq(0) .input-txt').addClass('success')
                            $('.item:eq(1) .input-txt').addClass('error')
                        });

                    </script> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactContents;
